import React, { useContext, useState } from 'react';
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from 'react-markdown'
import {LanguageContext} from '../../components/LanguageProvider'
import ChildLayout from '../../components/childPageLayout'








export default function FullProfile({data}){




return (<ChildLayout>
  <IndividualProfileContent data = {data}/>
        </ChildLayout>
)

 }




export function IndividualProfileContent({ data }) {
  let context = useContext(LanguageContext)
  let details = data.allCmbPeopleJson.edges[0].node;


  let deptOrFak;
  if (details[context].department?.length >2) {
    deptOrFak = <dd>{details[context].department}</dd>
  } else {
    deptOrFak = <dd>{details[context].faculty}</dd>
  }

  let title;
  details.title !== null ? title = details.title : title = "";

  let name = title + " " + details.firstname + " " + details.surname;
  let [dept, faculty, uni, country, description] = [details[context].department, details[context].faculty, details[context].uni, details[context].country, details[context].description]
  let [email, gscholar, profile ] = [details.email, details.gscholar, details.profile]

  let image = getImage(details.image)

  return ( 
    <main className ={"individualProfilesmain"}>
    <h1>{name}</h1>
    <section className={"personDescription"}>
        <ReactMarkdown>{description}</ReactMarkdown>
    </section>
    <section className={"personProfileEssentials"}>
        <GatsbyImage className={"personProfileImage"} image={image} alt=""/>
        <dl>
        <dt>Primary Affilliation</dt>
        <dd>
             <p>{deptOrFak}</p>
             <p>{uni}</p>
        </dd>
        <dt>Contact</dt>
        <dd>{email}</dd>
        <dd><a href={gscholar}>Google Scholar</a></dd>
        <dd><a href={profile}>University Profile</a></dd>           
        </dl>     
    </section>
    </main>
  )
}

export const query = graphql`
query($id:String) {allCmbPeopleJson(filter:{id:{eq:$id}})
{

  edges{
  
  node{
  
  id
  title
  firstname
  surname
  email
  image{childImageSharp{gatsbyImageData}}
    gscholar
    profile
    en{description
    department
    faculty
    uni
    country}
    hr{description
    department
    faculty
    uni
    country}
  

  }}}}
  
 `

